<template>
    <div id="body">
        <div class="fatherBox">
            <div class="headerBox">
                <p>审核记录</p>
            </div>
            
            <el-table :data="advUserData" v-loading="loading">
                <el-table-column label="序号" align="center" width="130">
                    <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="adv_shang_name" label="申请广告服务商" align="center"> </el-table-column>
                
                <el-table-column prop="company_name" label="申请的广告主名字" align="center"> </el-table-column>
                
                <el-table-column prop="hangye_type" label="所在行业" align="center">
                    <template slot-scope="scope">
                        {{ hangyeOptions[scope.row.hangye_type] }}
                    </template>
                </el-table-column>
                
                <el-table-column prop="company_type" label="证件类型" align="center">
                    <template slot-scope="scope">
                        {{ typeOptions[scope.row.company_type] }}
                    </template>
                </el-table-column>
                
                <el-table-column prop="owner" label="法人姓名" align="center"> </el-table-column>
                
                <el-table-column prop="own_tel" label="法人手机号" align="center"> </el-table-column>
                
                <el-table-column prop="create_time" label="申请时间" align="center"> </el-table-column>
                
                <el-table-column prop="update_time" label="审核时间" align="center"> </el-table-column>
                
                <el-table-column prop="status" label="审核结果" align="center">
                    <template slot-scope="scope">
                        {{ statusOptions[scope.row.status] }}
                    </template>
                </el-table-column>
                
                <el-table-column label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false"
                            @click="detail(scope.row)">详情</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination @current-change="handleCurrentChange" :current-page="currpage" :page-size="pagesize"
                    background layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top:15px">
                </el-pagination>
            </div>
        </div>
        <el-dialog v-if="actionData" title="审核记录" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
            <el-form :model="Addadvertising" ref="articleForm" style="width:460px;margin:0 auto">
                <el-form-item label="申请广告服务商"> {{ actionData.adv_shang_name }} </el-form-item>
<el-form-item label="申请时间"> {{ actionData.create_time }} </el-form-item>
<el-form-item label="公司名称"> {{ actionData.company_name }} </el-form-item>
<el-form-item label="所在行业"> {{ hangyeOptions[actionData.hangye_type] }} </el-form-item>
<el-form-item label="证件类型"> {{ typeOptions[actionData.company_type] }} </el-form-item>
<el-form-item label="营业执照"> <el-image @click="onOpenImage(actionData.pic)" :src="actionData.pic" style="width:100px;height:100px;" /> </el-form-item>
<el-form-item label="统一社会信用代码"> {{ actionData.company_id }} </el-form-item>
<el-form-item label="证件有效期"> {{ actionData.id_time }} - {{ actionData.id_time_end }} </el-form-item>
<el-form-item label="注册地"> {{ actionData.province }}{{ actionData.city }} </el-form-item>
<el-form-item label="法人手机号"> {{ actionData.owner_tel }} </el-form-item>
<el-form-item label="公司电话"> {{ actionData.company_tel }} </el-form-item>
<el-form-item label="法人姓名"> {{ actionData.owner }} </el-form-item>
<el-form-item label="法人身份证号"> {{ actionData.owner_id }} </el-form-item>
<el-form-item label="身份证有效期"> {{ actionData.owner_id_time }} - {{ actionData.owner_id_time_end }} </el-form-item>
<el-form-item label="法人身份证照片"> <el-image @click="onOpenImage(actionData.owner_pic_z)" :src="actionData.owner_pic_z" style="width:100px;height:100px;margin-right:10px;" /><el-image :src="actionData.owner_pic_b" @click="onOpenImage(actionData.owner_pic_b)" style="width:100px;height:100px;" /> </el-form-item>
<el-form-item label="上传行业资质"> <el-image @click="onOpenImage(actionData.zz_pic)" :src="actionData.zz_pic" style="width:100px;height:100px;" /> </el-form-item>
<el-form-item label="审核时间"> {{ actionData.update_time }}</el-form-item>
<el-form-item label="审核结果 "> {{ statusOptions[actionData.status] }} </el-form-item>

            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import {
    schoolList,
    advUserList,
    advUserStatusList,
    advStatus
} from '@/api/adminApi.js'
export default {
    data() {
        return {
            statusOptions: {"0":"审核中", "1":"通过", "2":"拒绝"},
            actionData: null,
            typeOptions:{"1":"企业", "2":"个体工商户"},
            hangyeOptions: {"1":"教育/培训",
                "2": "休闲娱乐/餐饮/服务",
                "3": "旅游/酒店",
                "4": "互联网/电子商务",
                "5": "批发/零售",
                "6": "服装/纺织",
                "7": "家具灯饰",
                "8": "快速消费品（食品、饮料等）",
                "9": "耐用消费品（家具、家电等）",
                "10": "其他行业"
            },
            advUserData: [], // 广告主列表数据
            dialogFormVisible: false, // 添加广告主弹窗
            loading: false, //加载框
            pagesize: 10, // 每页显示条目个数
            currpage: 1, // 初始页
            CountNum: 0, // 总条数
            serchList: {}, //搜索栏查询
            schoolData: [], //学校集合
            stateList: [], //状态集合
            regionList: [], //地区集合
            Addadvertising: {} // 添加新的广告主
        }
    },
    mounted() {
        this.advUserList({}, 1) // 获取广告主列表
        this.schoolList() // 获取学校列表
    },
    methods: {
        
        onReject() {
            this.$prompt('请输入拒绝理由', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({value})=>{
                this.onApproval(2, value)
            })
        },
        onApproval(status=1, remark='') {
            const data = {
                id: this.actionData.id,
                status: status,
                remark: remark
            }
            advStatus(data).then(res=>{

                this.dialogFormVisible = false
                this.advUserList({}, 1)
            }).catch(err=>{
                console.log(err)
            })
        },
        onOpenImage(url) {
            window.open(url)
        },
        reset() { //重置按钮
            this.serchList = {}
            this.advUserList({}, 1)
        },
        advUserList(serchJson, currpage) { // 广告主列表
            this.loading = true
            const data = serchJson
            data["page"] = currpage //	当前页数
            data["limit"] = this.pagesize // 每页条数
            data['status'] = 3
            advUserStatusList(data).then(res => {
                this.CountNum = parseInt(res.data.CountNum) // 总条数
                this.advUserData = res.data.List // 设备列表
                this.currpage = currpage //分页显示当前页
                this.loading = false
            }).catch(err => {
                console.log(err)
            })
        },
        schoolList() { // 搜索栏学校列表
            const data = {}
            data["page"] = 1 //	当前页数
            data["limit"] = 1000 // 每页条数
            schoolList(data).then(res => {
                this.schoolData = res.data.List.map((item, index) => {
                    return Object.assign({}, {
                        'schoolName': item.SchoolName
                    }, {
                        'id': item.ID
                    })
                })
            }).catch(err => {
                console.log(err)
            })
        },
        detail(row) { // 点击充值详情
            this.actionData = row
            this.dialogFormVisible = true
        },
        toRecharge(row) { // 点击充值
            let data = JSON.stringify(row)
            this.$router.push({
                name: 'advUserRecharge',
                params: {
                    detailInfo: data
                }
            })
        },
        handleCurrentChange(val) { // 分页
            this.advUserList({}, val)
        }
    }
}
</script>

<style lang="scss" scoped>
.headerBox {
    display: flex;
    height: 52px;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e9e9e9;

    p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
    }

    .btn {
        display: flex;
        align-items: center;
        width: 125px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
    }
}

.query {
    width: 98%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 32px;

    .queryLeft {
        width: 80%;
        display: flex;

        div {
            width: 22%;
            text-align: left;

            .input,
            .select {
                width: 60%;
            }
        }
    }
}
</style>
